import React from "react"
import BlackCloud from "../images/blackCloud.png"
import Stars from "../images/stars.png"
import Icon from "../images/icon.png"
import ImageContainer from "./imageContainer"
import AppStore from "../images/appstore.png"
import PlayStore from "../images/playstore.png"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="relative">
      <ImageContainer
        container="w-full absolute mt-20 lg:px-16"
        src={Stars}
        alt="Stars"
        className="object-cover h-40 sm:h-56  md:h-64 mx-auto lg:h-auto object-right"
      />
      <ImageContainer
        container="w-full"
        src={BlackCloud}
        alt="Black Cloud"
        className="object-cover object-left h-32 sm:h-40 md:h-50 lg:h-auto"
      />
      <div className="footerGradient pt-48">
        <div className="container">
          <div className="w-48 px-1 mx-auto">
            <Link to="/" >
              <ImageContainer container="w-full" src={Icon} alt="Icon" />
            </Link>
          </div>
          <h3 className="text-center text-white plus-bold text-5xl">
            Baby Blocks Tracker
          </h3>

          <div className="flex justify-center  my-6 sm:my-10">
            <a
              href="https://apps.apple.com/us/app/blocks-baby-tracker/id1474383328"
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <ImageContainer
                container="w-40 px-1 sm:w-32 md:w-40 md:mr-5 md:px-0 lg:mr-0 lg:w-48 lg:pr-3"
                src={AppStore}
                alt="Apple Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=co.hellobuild.babytracker"
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <ImageContainer
                container="w-40 px-1 sm:w-32 md:w-40 md:px-0 lg:w-48 lg:pl-3"
                src={PlayStore}
                alt="Google Play Store"
              />
            </a>
          </div>
          <div className="flex flex justify-center text-white plus-bold text-md">
            <Link
              to="/privacy-policy"
              className="border-b-2 border-transparent hover:border-white"
            >
              Privacy
            </Link>
            <Link
              to="/terms-of-service"
              className="ml-10 border-b-2 border-transparent hover:border-white"
            >
              Terms Of Service
            </Link>
          </div>
        </div>
        <div className="bg-secondary mt-20">
          <div className="container text-white plus-bold text-xs text-center sm:text-md  sm:flex  sm:justify-between py-5">
            <p>© 2020 Baby Blocks Tracker. All rights reserved.</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hellobuild.co/"
              className="mt-3 sm:mt-0 sm:ml-10 hover:text-teal-400 active:text-teal-600 "
            >
              Made with ♥ HelloBuild, LLC
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
